/* Buttons */

.btn {
  font-size: $btn-font-size;
  line-height: 1;
  font-weight: 500;
  i {
    font-size: 1rem;
    &.text-extra-small {
      font-size: .75rem;
    }
  }
  &.btn-rounded {
    @include border-radius(50px);
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
  }
  &.btn-md {
    font-size: $btn-font-size-md;
  }
  &.btn-lg {
    font-size: $btn-font-size-lg;
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
  }
  /* Buttons with only icons */
  &.btn-icon {
    width: 42px;
    height: 42px;
    padding: 0;
  }
  /* Buttons with icon and text */
  &.btn-icon-text {
    .btn-icon-prepend {
      margin-right: .5rem;
    }
    .btn-icon-append {
      margin-left: .5rem;
    }
  }
  &.btn-social-icon {
    width: 50px;
    height: 50px;
    padding: 0;
  }
}

.btn-group {
  .btn {
    + .btn {
      border-left: 0;
    }
  }
}

.btn-toolbar {
  .btn-group {
    +.btn-group {
      @extend .ml-2;
    }
  }
}
.link-btn-group {
  .btn-link {
    position: relative;
    font-weight: normal;
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background: theme-color(dark);
      position: absolute;
      top: 0;
      right: 0;
    }
    &:hover {
      text-decoration: none;
    }
    &:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
  &.rtl {
    .btn-link {
      &:after {
        right: auto;
        left: 0;
      }
    }
  }
}
/*social buttons*/
@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include social-button(social-color($color));
  }
  .btn-outline-#{$color} {
    @include social-outline-button(social-color($color));
  }
}
/* inverse buttons */
@each $color, $value in $theme-colors {
  .btn-inverse-#{$color} {
    @include button-inverse-variant($value);
  }
  .btn-#{$color} {
    &,
    &:hover {
      box-shadow: 0 2px 2px 0 rgba(theme-color($color), .14), 
                0 3px 1px -2px rgba(theme-color($color), .2), 
                0 1px 5px 0 rgba(theme-color($color), .12)
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}