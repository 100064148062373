/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-dark-bg;
  font-family: $type1;
  font-weight: normal;
  padding: 0;
  width: $sidebar-width-lg; 
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  box-shadow: $sidebar-box-shadow;
  -webkit-box-shadow: $sidebar-box-shadow;
  -moz-box-shadow: $sidebar-box-shadow;
  -ms-box-shadow: $sidebar-box-shadow;
  position: relative;
  .dropdown {
    &.sidebar-profile-dropdown {
      margin: 1.25rem 2.375rem;
      .dropdown-toggle {
        background: $sidebar-dark-profile-bg;
        padding: .5rem .875rem;
        font-weight: 400;
        color: $white;
        font-size: 8px;
        border-radius: 3px;
        .sidebar-profile-icon {
          width: 25px;
          height: 25px;
          margin-right: .625rem;
          border-radius:100%;
        }
        .nav-profile-name {
          font-weight: 600;
          font-size: 12px;
        }
        &:hover {
          text-decoration: none;
        }
  
      }
      .dropdown-menu {
        margin-top: 0;
        width: 100%;
        background: $sidebar-dark-profile-bg;
        border:1px solid transparent;
        box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.115);
        padding: 0;
        .dropdown-item {
          padding: 1rem 1rem;
          color: $white;
          border-bottom: 1px solid darken($white,65%);
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background: $sidebar-dark-menu-hover-bg;
          }
        }
      }
    }
  }
  

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
        color: $sidebar-dark-menu-color;
        margin: 0 $sidebar-menu-margin-left 0 $sidebar-menu-margin-right;
        border-radius: 3px;
        @include transition-duration(0.45s);
        transition-property: all;
        -webkit-transition-property: all;

        i {
          color: $sidebar-dark-menu-icon-color;
          

          &.menu-icon { 
            background: $sidebar-dark-menu-icon-bg;
            font-size: $sidebar-icon-font-size;
            padding: .25rem;
            border-radius:2px;
            line-height: 1;
            margin-right: 1.125rem;
            .rtl & {
              margin-left: 2rem;
              margin-right: 0;
            }
            color: $sidebar-dark-menu-icon-color;
            &:before {
              vertical-align: middle; 
            }
          }
          &.menu-arrow {
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-left: auto;
            margin-right: 0;
            color: $sidebar-dark-menu-arrow-color;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }

            &:before {
              content: "\F142";
              font-family: "Material Design Icons";
              font-style: normal;
              display: block;
              font-size: 1rem;
              line-height: 10px;
              @include transition(all .2s ease-in);
            }
          }
        }

        .menu-title {
          color: $sidebar-dark-menu-color;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
        }

        .badge {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        &[aria-expanded="true"] {
          i {
            &.menu-arrow {
              &:before {
                @include rotate(90);
              }
            }
          }
        }
      }

      &.active {
        > .nav-link {
            background: $sidebar-dark-menu-active-bg;
            position: relative;
            i,
            .menu-title,
            .menu-arrow {
              color: $sidebar-dark-menu-active-color;
            }
        }
      }
      .sidebar-title {
        margin: 1.5rem 3.175rem .625rem 3.175rem;
        font-weight: 600;
        color: $sidebar-dark-menu-title-color;
        font-size: 14px;
      }
    }

    &:not(.sub-menu) {
      margin-top: 0rem;
      > .nav-item {
        margin-top: 1px;
        > .nav-link {
        }
        &:hover {
          > .nav-link {
            background: $sidebar-dark-menu-hover-bg;
            color: $sidebar-dark-menu-hover-color;
            letter-spacing: .09rem;                                    
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      margin-top:0;
      list-style: none;
      padding: $sidebar-submenu-padding;

      .nav-item {
        padding: 0;
        .nav-link {
          color: $sidebar-dark-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          margin:0 1.5rem;
          &:hover {
            color: $sidebar-dark-submenu-hover-color;
          }
          &.active {
            color: $sidebar-dark-submenu-active-color;
            background: transparent;
          }
          &::before {
            content: "\f13e";
            font-family: "Material Design Icons";
            position: absolute;
            margin-top: 13px;
            border-radius: 50%;
            top: 0;
            left: 0;
          }
          &:hover {
            letter-spacing: .09rem;       
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .designer-info {
    color: $sidebar-dark-designer-info-color;
    font-size: 12px;
    position: absolute;
    padding: 2.812rem 1.25rem 1rem 2.375rem;
    width: 100%;
    bottom: 0;
    a {
      color: $sidebar-dark-designer-info-color;
      font-size: 12px;
    }
    @media screen and (max-width: 991px) {
      position: static;
    }
  }
}
//sidebar color variation
.sidebar-light {
  .sidebar {
    background: $sidebar-light-bg;
    .dropdown {
      &.sidebar-profile-dropdown {
        margin: 1.25rem 2.375rem;
        .dropdown-toggle {
          background: $sidebar-light-profile-bg;
        }
      }
    }
    .nav {
      .nav-item {
        .nav-link {
          color: $sidebar-light-menu-color;
          font-weight: 500;
          i {
            color: inherit;
            &.menu-icon {
              color: $sidebar-light-menu-icon-color;
            }
          }
          .menu-title {
            color: inherit;
          }
        }
        &.active {
          > .nav-link {
            background: $sidebar-light-menu-active-bg;
            .menu-title,
            i {
              color: $sidebar-light-menu-active-color; 
            }
          }
        }
        .sidebar-title {
          color: $sidebar-light-menu-title-color;
        }
      }
      &:not(.sub-menu) {
        > .nav-item {
          &:hover {
            > .nav-link {
              background: $sidebar-light-menu-hover-bg;
              color: $sidebar-light-menu-hover-color;                                    
            }
          }
        }
      }
      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-light-submenu-color;
            &:before {
              color: lighten($sidebar-light-submenu-color, 10%);
            }
            &.active {
              color: $sidebar-light-menu-active-color;
              background: transparent;
            }
            &:hover {
              color: $sidebar-light-submenu-hover-color;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .designer-info {
      color: $sidebar-light-designer-info-color;
      a {
        color: $sidebar-light-designer-info-color;
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}