/* Dropdowns */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "Material Design Icons";
      content: "\f35d";  
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: .625rem;
      font-size: 1.25rem;
      margin-left: 0;
    }
    &.btn-outline-light {
      &:hover,
      &:focus,
      &:active  {
        background: transparent;
        color: $body-color;;
        border-color: $border-color;
      }
    }
  } 
  .dropdown-divider {
    margin: 0 0;
  }
  .dropdown-menu {
    margin-top: 1px;
    padding: 0; 
    font-size: $default-font-size;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); 
    border-radius: 0;
    .dropdown-item {
      font-size: .875rem;
      padding: 0.5rem 1rem;
      font-size: 12px;
      border-bottom: 1px solid $border-color;
      &:last-child {
      border-bottom: none;
      }
      &:active {
        background: initial;
      }
    }
  }
  &.arrow-none {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
}
